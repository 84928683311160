import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UploadingFileStatus, {
    formatFileSize
} from 'components/fileUpload/uploadingFileStatus/uploadingFileStatus';
import UploadedFileStore from 'core/store/uploadedFile.store/uploadedFile.store';
import { useTranslation } from 'react-i18next';
import {
    DocumentUploadType,
    FormalDocumentUploadType,
    GroupedByPrimaryIdAndPostURL,
    OriginalFileObject,
    ReplaceExistingDocumentType,
    TransformedObject
} from 'components/fileUpload/types/fileUpload.types';
import fileUploadService from './services/fileUpload.service';

function FileUploadController() {
    const { t } = useTranslation();
    const [fileUploadStatusCount, setFileUploadStatusCount] = useState<any>(
        UploadedFileStore.initialState.files
    );
    const [minMaxPopupFlag, setMinMaxPopup] = useState(false);
    const [fileUploadingPopup, setFileUploadingPopup] = useState(true);
    const location = useLocation();

    const fileUploadCompletedLength =
        fileUploadStatusCount?.length &&
        fileUploadStatusCount?.filter((file: { progress: number }) => file.progress >= 100.0)
            .length;

    useLayoutEffect(() => {
        const subscription = UploadedFileStore.subscribe(setFileUploadStatusCount);
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        const pathnameCheck =
            location.pathname.includes('file-upload') ||
            location.pathname.includes('task-management/details');
        const hashCheck = location.hash === '#file-upload';
        (pathnameCheck || hashCheck) && setMinMaxPopup(false);
    }, [location.pathname, location.hash]);

    useEffect(() => {
        if (
            fileUploadStatusCount.length > 0 &&
            fileUploadStatusCount.length === fileUploadCompletedLength
        ) {
            // Grouping by primaryId and postURL, and separating each into individual arrays
            const groupedByPrimaryIdAndPostURL: GroupedByPrimaryIdAndPostURL =
                UploadedFileStore.initialState.files.reduce(
                    (acc: GroupedByPrimaryIdAndPostURL, file: OriginalFileObject) => {
                        const primaryId = file.primaryId;
                        const postURL = file.uploadFileApiUrl;

                        if (!acc[primaryId]) {
                            acc[primaryId] = {};
                        }

                        if (!acc[primaryId][postURL]) {
                            acc[primaryId][postURL] = [];
                        }

                        acc[primaryId][postURL].push({
                            primaryId: file.primaryId,
                            postURL: file.uploadFileApiUrl,
                            filePayload: {
                                name: file.name,
                                size: file.size,
                                type: file.type,
                                progress: file.progress,
                                file: file.file,
                                documentType: file.documentType,
                                azureBlobFolderPath: file.azureBlobFolderPath,
                                azureBlobFileName: file.azureBlobFileName,
                                moduleName: file.moduleName,
                                ifFormalDocumentUpload: file.ifFormalDocumentUpload,
                                formalDocumentFields: file.formalDocumentFields
                            }
                        });

                        return acc;
                    },
                    {} as GroupedByPrimaryIdAndPostURL
                );

            // Iterating over each primaryId and postURL to call the API separately
            Object.entries(groupedByPrimaryIdAndPostURL).forEach(
                ([primaryId, postURLData]: [
                    string,
                    { [postURL: string]: TransformedObject[] }
                ]) => {
                    Object.entries(postURLData).forEach(([postURL, files]) => {
                        const payloads = files.map((file) => file.filePayload);
                        // Check the type of document upload once per group
                        const isFormalDocument =
                            files[0]?.filePayload.ifFormalDocumentUpload.isFormalDoc;
                        const isReplaceExisting =
                            files[0]?.filePayload.ifFormalDocumentUpload.isExistingDocReplace;
                        if (isFormalDocument) {
                            if (isReplaceExisting) {
                                // Handle Replace Existing Formal Document
                                const transformedPayload =
                                    transformReplaceExistingFormalDocument(payloads);
                                fileUploadService
                                    .replaceExistingFormalDocument(
                                        transformedPayload as ReplaceExistingDocumentType,
                                        postURL
                                    )
                                    .catch((error) => {
                                        console.error(
                                            `Upload failed for primaryId ${primaryId}:`,
                                            error
                                        );
                                    });
                            } else {
                                // Handle Formal Document
                                const transformedPayload = transformFormalDocumentFields(payloads);
                                fileUploadService
                                    .formalDocument(
                                        transformedPayload as FormalDocumentUploadType,
                                        postURL
                                    )
                                    .catch((error) => {
                                        console.error(
                                            `Upload failed for primaryId ${primaryId}:`,
                                            error
                                        );
                                    });
                            }
                        } else {
                            // Handle Standard Document
                            const transformedPayload = transformFiles(payloads);
                            fileUploadService
                                .documentUpload(transformedPayload as DocumentUploadType, postURL)
                                .catch((error) => {
                                    console.error(
                                        `Upload failed for primaryId ${primaryId}:`,
                                        error
                                    );
                                });
                        }
                    });
                }
            );

            setTimeout(() => {
                setFileUploadingPopup(false);
                UploadedFileStore.handleFileUploadComplete(t('File_UPLOAD.UPLOAD_SUCCESS_TOAST'));
            }, 2000);
        }
    }, [fileUploadCompletedLength, fileUploadStatusCount]);

    // Function to transform the incoming files array
    const transformFiles = (files: any) => {
        return files.map(
            (file: {
                name: string;
                type: string;
                size: number;
                documentType: string;
                azureBlobFolderPath: string;
                azureBlobFileName: string;
            }) => ({
                fileName: file.name, // Renamed from 'name'
                fileType: file.type, // Renamed from 'type'
                fileSize: formatFileSize(file.size), // Converted size to KB with two decimals
                blobFileName: file.azureBlobFolderPath + '/' + file.azureBlobFileName, // Format the blob file name
                documentType: file.documentType // Kept as is
            })
        );
    };

    const transformFormalDocumentFields = (files: any) => {
        return files.map((file: any) => {
            return {
                fileName: file.name,
                fileType: file.type,
                fileSize: formatFileSize(file.size as number),
                blobFileName: file.azureBlobFolderPath + '/' + file.azureBlobFileName,
                documentType: file.formalDocumentFields.documentType,
                targetCountryId: file.formalDocumentFields?.targetCountryId,
                hardCopy: file.formalDocumentFields?.hardCopy,
                expectedDeliveryDate: file.formalDocumentFields?.expectedDeliveryDate,
                vendorName: file.formalDocumentFields?.vendorName,
                vendorPhoneNumber: file.formalDocumentFields?.vendorPhoneNumber,
                vendorEmail: file.formalDocumentFields?.vendorEmail,
                disPatchAddress: file.formalDocumentFields?.disPatchAddress
            };
        });
    };

    const transformReplaceExistingFormalDocument = (files: any) => {
        const file = files[0]; // Assuming the first file is the one to send if there are multiple
        return {
            fileName: file.name,
            fileType: file.type,
            fileSize: formatFileSize(file.size as number),
            blobFileName: file.azureBlobFolderPath + '/' + file.azureBlobFileName,
            documentType: file.formalDocumentFields.documentType,
            expectedDeliveryDate: file.formalDocumentFields.expectedDeliveryDate,
            hardCopy: file.formalDocumentFields.hardCopy
        };
    };
    useEffect(() => {
        if (fileUploadStatusCount.length > 0 && !fileUploadingPopup) {
            setFileUploadingPopup(true);
        }
    }, [fileUploadStatusCount]);

    const handleUploadingPopup = () => {
        setMinMaxPopup(!minMaxPopupFlag);
    };

    return (
        <div>
            {fileUploadingPopup && fileUploadStatusCount.length > 0 && (
                <>
                    <div
                        className={`absolute right-0 bottom-80 z-[55] ${minMaxPopupFlag ? 'hidden' : ''}`}
                        onClick={handleUploadingPopup}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                handleUploadingPopup();
                            }
                        }}
                        role="button"
                        tabIndex={0}>
                        <div
                            className={`h-14 w-16 text-white rounded-s-xl font-medium flex flex-col justify-center items-center cursor-pointer ${fileUploadStatusCount.length === fileUploadCompletedLength ? 'bg-success' : 'bg-primary'}`}>
                            {fileUploadCompletedLength} / {fileUploadStatusCount.length}
                            <i
                                className={`${fileUploadStatusCount.length === fileUploadCompletedLength ? 'pi pi-check-circle' : 'pi pi-cloud-upload animate-bounce'}`}></i>
                        </div>
                    </div>

                    <div
                        className={`absolute right-4 bottom-16 w-3/4 lg:w-1/3 z-[55] ${!minMaxPopupFlag ? 'hidden' : ''}`}>
                        <div className="relative">
                            <i
                                className="pi pi-window-maximize cursor-pointer absolute right-6 top-6"
                                onClick={handleUploadingPopup}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        handleUploadingPopup();
                                    }
                                }}
                                role="button"
                                tabIndex={0}></i>
                            <UploadingFileStatus moduleName="" />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default FileUploadController;
