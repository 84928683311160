import { ALLOWED_EXTENSION } from '../../../constants/fileSupportExtension';
import { Subject } from 'rxjs';
import { handleAzureStorageUploadTheFile } from 'components/fileUpload/services/azureStorage.service';
import NotificationStore, {
    ToastType,
    notificationModel
} from '../notificationStore/notificationStore';

const subject = new Subject();
export interface UploadedFileStoreProps {
    name: string;
    size: number;
    type: string;
    progress: number;
    file: File;
    documentType: number;
    azureBlobFolderPath: string;
    azureBlobFileName: string;
}

export interface notClassifiedProps {
    notClassifiedType: boolean;
}

const initialFileUpload: any = {
    files: [],
    notClassifiedType: true
};

let state = initialFileUpload;

const UploadedFileStore = {
    init() {
        state = { ...state };
        subject.next(state);
    },
    subscribe: (setState: (value: any) => void) => subject.subscribe(setState),
    updateData(values: UploadedFileStoreProps[]) {
        let responseResult = null;
        const unsupportedFiles: string[] = [];

        for (const value of values) {
            const isFileValidResult = isfileValid(
                value.file,
                value.azureBlobFolderPath,
                value.azureBlobFileName
            );

            if (!isFileValidResult) {
                unsupportedFiles.push(value.name);
                continue; // Skip this file if it is not valid
            }

            const existingFileIndex = state.files.findIndex(
                (file: { name: string }) => file.name === value.name
            );

            if (existingFileIndex !== -1) {
                state.files = [
                    value,
                    ...state.files.slice(0, existingFileIndex),
                    ...state.files.slice(existingFileIndex + 1)
                ];
            } else {
                state.files = [value, ...state.files];
            }

            if (isFileValidResult) {
                responseResult = isFileValidResult;
            }
        }

        if (unsupportedFiles.length > 0) {
            showToastMessage(
                `${unsupportedFiles.length} unsupported file(s): ${unsupportedFiles.join(', ')}`,
                ToastType.WARNING
            );
        }

        if (responseResult) {
            return responseResult;
        }

        subject.next(state.files);
    },
    UploadingFileStatus(filename: string, percentage: string) {
        const existingFileIndex = state.files.findIndex(
            (file: { name: any }) => file.name === filename
        );

        if (existingFileIndex !== -1) {
            state.files = state.files.map((file: any, index: any) => {
                if (index === existingFileIndex) {
                    return { ...file, progress: percentage };
                } else {
                    return file;
                }
            });

            subject.next(state.files);
        }
        subject.next(state.files);
    },
    deleteTheFile(filename: any) {
        const fileIndexToDelete = state.files.findIndex(
            (file: { name: any }) => file.name === filename
        );

        if (fileIndexToDelete !== -1) {
            state.files = state.files.filter((_: any, index: any) => index !== fileIndexToDelete);
            subject.next(state.files);
        }
    },
    fileArrayEmpty() {
        state.files = [];
        subject.next(state.files);
    },
    resetFileUpload() {
        state = {
            files: [],
            collectionId: null
        };
        subject.next(state);
    },
    initialState: initialFileUpload,
    handleFileUploadComplete(toastMsg: string) {
        this.fileArrayEmpty();
        showToastMessage(toastMsg, ToastType.SUCCESS);
    }
};

export default UploadedFileStore;

function isfileValid(
    fileWithDetails: File,
    azureBlobFolderPath: string,
    azureBlobFileName: string
) {
    const isSupportedType = ALLOWED_EXTENSION.some((extension) =>
        fileWithDetails.type.toLowerCase().split('/')[1].includes(extension)
    );
    const isWithinSizeLimit = fileWithDetails.size <= 1024 * 1024 * 1024;
    const fileTypeSizeFlagResult = isSupportedType && isWithinSizeLimit;

    if (fileTypeSizeFlagResult) {
        const response: any = handleAzureStorageUploadTheFile(
            fileWithDetails,
            azureBlobFolderPath,
            azureBlobFileName
        );
        return response;
    }
    return false;
}

// Showing toast message
const showToastMessage = (toastMsg: string, errorType: ToastType) => {
    const message: notificationModel = {
        message: toastMsg,
        type: errorType
    };
    NotificationStore.showAlert(message);
};
