import pdf from 'assets/svgs/pdf-file.svg';
import doc from 'assets/svgs/doc-file.svg';
import xls from 'assets/svgs/xls-file.svg';
import png from 'assets/svgs/png-file.svg';
import svg from 'assets/svgs/svg-file.svg';
import zip from 'assets/svgs/zip-file.svg';
import csv from 'assets/svgs/csv-file.svg';

export const fileTypeIcon = (filetype: string) => {
    let icon;
    // Extract the subtype from the MIME type
    const subtype = filetype.toLowerCase().split('/')[1];

    if (subtype.includes('pdf')) {
        icon = pdf;
    } else if (subtype.includes('msword')) {
        icon = doc;
    } else if (subtype.includes('ms-excel')) {
        icon = xls;
    } else if (subtype.includes('png')) {
        icon = png;
    } else if (subtype.includes('svg+xml')) {
        icon = svg;
    } else if (subtype.includes('zip')) {
        icon = zip;
    } else if (subtype.includes('csv') || subtype.includes('spreadsheetml.sheet')) {
        icon = csv;
    } else {
        icon = doc;
    }
    return <img src={icon} className="w-6 h-5" />;
};
